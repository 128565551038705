<div class="logo"><a pRipple href="/home"></a></div>

<div class="sidebar-menu">
  <div class="menu-category" pRipple pTooltip="{{ (!expanded ? 'user.user' : '') | translate }}"
    tooltipPosition="right" routerLinkActive="active" routerLink="user">
    <div class="flex align-items-center"><i class="pi pi-users icon"></i> <span>{{ 'user.user' | translate }}</span></div>
  </div>
</div>

<div class="user flex align-items-center justify-content-center">
  <div class="flex align-items-center user-button" (click)="user.toggle($event)">
    <i class="pi pi-user"></i> <span>{{ username }}</span>
  </div>
</div>

<p-menu #user [popup]="true" [model]="menuUser"></p-menu>