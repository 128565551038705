import { Injectable } from '@angular/core';
import { NovelAuthResolver } from 'ngx-novel';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthResolver extends NovelAuthResolver {

  constructor(authService: AuthService) {
    super(authService);
  }
}
