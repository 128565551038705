import { Component, HostListener, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styles: [],
  animations: [
    trigger('sidebarLayout', [
      state('collapsed', style({
        width: '44px',
      })),
      state('expanded', style({
        width: '240px',
      })),
      transition('collapsed => expanded', [
        animate('0.25s')
      ]),
      transition('expanded => collapsed', [
        animate('0.2s')
      ]),
    ]),
    trigger('mainLayout', [
      state('collapsed', style({
        marginLeft: '45px',
      })),
      state('expanded', style({
        marginLeft: '240px',
      })),
      transition('collapsed => expanded', [
        animate('0.25s')
      ]),
      transition('expanded => collapsed', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class LayoutComponent implements OnInit {

  sidebar = 'expanded';
  expanded = true;

  ngOnInit(): void {
    if (localStorage.getItem('sidebar')) {
      this.sidebar = localStorage.getItem('sidebar') || 'expanded';
      this.expanded = this.sidebar === 'expanded';
    }
  }

  onSidebarToggle() {
    this.sidebar = this.expanded ? 'collapsed' : 'expanded';
    this.expanded = !this.expanded;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (
      (event.target.innerWidth < 760 && this.expanded) ||
      (760 < event.target.innerWidth && !this.expanded)
    ) {
      this.onSidebarToggle();
    }
  }
}
