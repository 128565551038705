import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

import { API_NODE } from 'src/app/shared/constants';

@Injectable({ providedIn: 'root' })
export class CcsService {

  private nodes: any = {};

  constructor(private http: HttpClient) { }

  get$(slug: number): Observable<any[]> {
    if (this.nodes[slug]) {
      return of(this.nodes[slug].slice());
    }
    return this.http.get<any[]>(API_NODE + slug).pipe(
      tap((val: any[]) => {
        this.nodes[slug] = [...val];
      }),
    );
  }

  get(slug: number): any[] {
    if (this.nodes[slug]) {
      return this.nodes[slug].slice();
    }
    return [];
  }

}
