import { Injectable } from '@angular/core';
import { NovelAuthInterceptor } from 'ngx-novel';

import { AuthService } from './auth.service';
import { backends, externals } from 'src/app/shared/constants';

@Injectable()
export class AuthInterceptor extends NovelAuthInterceptor {

  constructor(authService: AuthService) {
    super(authService, backends, externals);
  }
}
