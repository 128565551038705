import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Error } from 'ngx-novel';

import { AuthService } from './core/auth/auth.service';
import { ErrorService } from './core/error/error.service';
import { LoadingService } from './core/loading/loading.service';
import { ThemeService } from './core/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit {

  loading = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private authService: AuthService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private themeService: ThemeService,
  ) {
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.themeService.load();
    const translate = this.translateService;

    this.authService.authSubject$.subscribe((val: string) => {
      if (val === 'loggedIn') {
        this.router.navigate(['home']);
      }
    });

    this.errorService.errorSubject$.subscribe((e: Error) => {
      this.messageService.add({
        severity: 'error',
        summary: translate.instant('common.errorTitle', { status: e.status }),
        detail: translate.instant(e.detail || e.path || e.status.toString()),
      });

      if (e.status === 401) {
        setTimeout(() => {
          this.authService.empty();
          this.router.navigate(['login']);
        }, 5000);
      }
    });

    this.loadingService.loadingSubject$.subscribe((val: boolean) => {
      this.loading = val;
    });

  }
}
