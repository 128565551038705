<div class="top-loading">
    <div *ngIf="loading; else noLoading">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>

    <ng-template #noLoading>
        <p-progressBar value="0"></p-progressBar>
    </ng-template>
</div>

<router-outlet></router-outlet>

<p-confirmDialog [breakpoints]="{'1200px': '50vw', '960px': '70vw', '640px': '90vw'}" [style]="{width: '30vw'}"></p-confirmDialog>
<p-toast></p-toast>