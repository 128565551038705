import { environment } from 'src/environments/environment';

const core = environment.core;

export const backends = [core];
export const externals = ['/api/custom/external/'];

export const API_USER = core + 'api/custom/auth-user';
export const API_PERSON = core + 'api/custom/people';
export const API_NODE = core + 'api/custom/node-slug/';
export const API_NODES = core + 'api/custom/nodes';
export const API_OPERAS = core + 'api/custom/operas';
export const API_ACTORS = core + 'api/custom/actors';
export const API_CHANGE_PASSWORD = core + 'api/custom/change-password';
