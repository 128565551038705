<div class="web-layout">
    <div class="sidebar-layout" [@sidebarLayout]="sidebar" [ngClass]="{'collapsed': !expanded}">
        <app-sidebar [expanded]="expanded"></app-sidebar>
        <div class="sidebar-toggle" pRipple (click)="onSidebarToggle()">
            <i class="pi pi-angle-left"></i>
            <i class="pi pi-angle-right"></i>
        </div>
    </div>
    <div class="main-layout" [@mainLayout]="sidebar">
        <router-outlet></router-outlet>
    </div>
</div>
