import { Injectable } from '@angular/core';
import { NovelLoadingInterceptor } from 'ngx-novel';

import { LoadingService } from './loading.service';

@Injectable()
export class LoadingInterceptor extends NovelLoadingInterceptor {

  constructor(loadingService: LoadingService) {
    super(loadingService);
  }
}