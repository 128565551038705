import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';

import { CcsService } from 'src/app/core/ccs.service';

@Injectable({ providedIn: 'root' })
export class UserCcsResolver implements Resolve<any[]> {

  constructor(
    private ccsService: CcsService,
  ) { }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
    return forkJoin([
      this.ccsService.get$(1),
    ]);
  }
}