import { Component, Input, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/core/auth/auth.service';
import { ThemeService } from 'src/app/core/theme.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [
  ]
})
export class SidebarComponent implements OnInit {

  @Input() expanded: boolean = true;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    public themeService: ThemeService,
  ) { }

  username!: string;

  menuUser: MenuItem[] = [];

  menuLogout: MenuItem[] = [
    {
      label: this.translateService.instant('common.logout'),
      icon: 'pi pi-sign-out',
      command: () => { this.authService.logout(); },
    }
  ];

  menuLight: MenuItem[] = [
    {
      label: this.translateService.instant('common.lightTheme'),
      icon: 'pi pi-sun',
      command: () => {
        this.themeService.apply('saga-blue');
        this.theme();
      },
    },
    ...this.menuLogout,
  ];

  menuDark: MenuItem[] = [
    {
      label: this.translateService.instant('common.darkTheme'),
      icon: 'pi pi-moon',
      command: () => {
        this.themeService.apply('vela-blue');
        this.theme();
      },
    },
    ...this.menuLogout,
  ];

  ngOnInit(): void {
    this.username = this.authService.user.username;
    this.theme();
  }

  theme(): void {
    this.menuUser = this.themeService.name === 'saga-blue' ? this.menuDark : this.menuLight;
  }

}
