import { Injectable } from '@angular/core';
import { NovelErrorService, Error } from 'ngx-novel';

@Injectable({ providedIn: 'root' })
export class ErrorService extends NovelErrorService {

  override catch(e: Error): void {
    if (e.status === 0) {
      e.status = 401;
    }
    if (this.checkError(e)) {
      this._errorSubject$.next(e);
    }
  }

}