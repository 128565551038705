import { Injectable } from '@angular/core';
import { NovelErrorInterceptor } from 'ngx-novel';

import { ErrorService } from './error.service';

@Injectable()
export class ErrorInterceptor extends NovelErrorInterceptor {

  constructor(errorService: ErrorService) {
    super(errorService);
  }
}
