import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NovelAuthGuard } from 'ngx-novel';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard extends NovelAuthGuard {

  constructor(
    router: Router,
    authService: AuthService,
  ) {
    super(router, authService);
  }
}
