import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NovelThemeService } from 'ngx-novel';

@Injectable({ providedIn: 'root' })
export class ThemeService extends NovelThemeService {

  constructor(@Inject(DOCUMENT) document: Document) {
    super(document);
  }
}