import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NovelAuthService } from 'ngx-novel';

import { environment } from 'src/environments/environment';
import { API_USER } from 'src/app/shared/constants';

@Injectable({ providedIn: 'root' })
export class AuthService extends NovelAuthService {

  protected _isAdmin: boolean = false;

  constructor(
    http: HttpClient
  ) {
    super(http, API_USER, environment.keycloak, environment.realm);
  }

  get isAdmin(): boolean { return this._isAdmin; }
}
